import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Markdown from 'react-markdown';

import styles from './privacy.module.scss';
const Privacy = () => {
  const query = useStaticQuery(graphql`
    query {
      allStrapiPrivacyPolicyPage {
        nodes {
          title
          policy
        }
      }
    }
  `);

  const { policy, title } = query.allStrapiPrivacyPolicyPage.nodes[0];
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>{title}</h2>
      <hr className={styles.line} />
      <div className={styles.content}>
        <Markdown source={policy} escapeHtml={false} />
      </div>
    </div>
  );
};

export default Privacy;
